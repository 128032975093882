import axiosInstance, {fileUploadInstance} from "."
import {CandidateCreateRequest} from "../types/candidate/CandidateCreateRequest"
import {ICandidateCommentCreate} from "../types/candidate/ICandidateCommentCreate"
import {ICandidateGetFilter} from "../types/candidate/ICandidateGetFilter"
import {ResumeCreateRequest} from "../types/candidate/ResumeCreateRequest"
import {ICandidateFull} from "../types/candidate/ICandidateFull";
import {useMutation, useQuery} from "@tanstack/react-query";

class CandidateService {
  async create(formData: CandidateCreateRequest) {
    const response = await axiosInstance.post('/api/candidate', formData)
    return response
  }

  async findAll(filter: ICandidateGetFilter) {
    const response = await axiosInstance.post('/api/candidate/findAll', filter)
    return response
  }

  async findById(id: number) {
    const response = await axiosInstance.get('/api/candidate/' + id)
    return response
  }

  async appendResume(formData: ResumeCreateRequest) {
    const fd = new FormData()
    fd.append('candidateId', formData.candidateId.toString())
    fd.append('file', formData.file as Blob)
    fd.append('name', formData.name)
    const response = await fileUploadInstance.post('/api/candidate/appendResume', fd)
    return response
  }

  async getVacancySteps(vacancyId: number, resumeId: number) {
    const response = await axiosInstance.get('/api/candidate/getVacancySteps/' + vacancyId + '/' + resumeId)
    return response
  }

  async getVacancyStepsShort(vacancyId: number, resumeId: number) {
    const response = await axiosInstance.get('/api/candidate/getVacancyStepsShort/' + vacancyId + '/' + resumeId)
    return response
  }

  async getComments(vacancyId: number, resumeId: number) {
    const response = await axiosInstance.get('/api/candidate/getComments/' + vacancyId + '/' + resumeId)
    return response
  }

  async addComment(fd: ICandidateCommentCreate) {
    const response = await axiosInstance.post('/api/candidate/addComment', fd)
    return response
  }

  async deleteComment(id: number) {
    const response = await axiosInstance.delete('/api/candidate/deleteComment/' + id)
    return response
  }

  async updateComment(id: number, text: string, type: number) {
    const response = await axiosInstance.put('/api/candidate/updateComment/' + id, {text, type})
    return response
  }

  async deleteResume(id: number) {
    const response = await axiosInstance.delete('/api/candidate/deleteResume/' + id)
    return response
  }

  async getVacancies(id: number) {
    const response = await axiosInstance.get('/api/candidate/getVacancies/' + id)
    return response
  }

  async deleteCandidate(id: number) {
    const response = await axiosInstance.delete<ICandidateFull>('/api/candidate/' + id)
    return response
  }

  async candidateCategories() {
    return await axiosInstance.get<any>('/api/candidate-category/find')
  }

  async candidateCategoriesByCode(code: string) {
    return await axiosInstance.get<any>(`/api/candidate-category/find-code/${code}`)
  }
}

const candidateService = new CandidateService()

export default candidateService
// Функция для получения данных
const fetchCandidateCategories = async () => {
  const response = await axiosInstance.get<any>('/api/candidate-category/find');
  return response.data;
};

const useCandidateCategories = () => {
  return useQuery<any, Error>({
    queryKey: ['candidateCategories'], // ключ запроса в виде массива
    queryFn: fetchCandidateCategories, // функция запроса
  });
};

const fetchCandidateCategoriesWithCount = async () => {
  const response = await axiosInstance.get<any>('/api/candidate-category/find-all-count');
  return response.data;
};

const useCandidateCategoriesWithCount = () => {
  return useQuery<any, Error>({
    queryKey: ['fetchCandidateCategoriesWithCount'], // ключ запроса в виде массива
    queryFn: fetchCandidateCategoriesWithCount, // функция запроса
  });
};



type UpdateCandidateCategoryResponse = any; // Define the type of the response you expect
type UpdateCandidateCategoryVariables = { id: string, dto: any }; // Define the variables type
interface DeleteCandidateCategoryDto {
  currentCategoryId: number, newCategoryId: number
}

interface DeleteCategoryAssociationDto {
  currentCategoryId: number
}

interface AddCandidateCategoryDto {
  currentCategoryId: number, candidateId: number
}
interface CandidateCategoryDto {
  name: string;
  code: string;
}

const updateCandidateCategory = async ({id, dto}: UpdateCandidateCategoryVariables) => {
  const response = await axiosInstance.patch<any>(`/api/candidate-category/update/${id}`, dto);
  return response.data;
};

export const useUpdateCandidateCategory = (id: string, dto: any) => {
  return useMutation<UpdateCandidateCategoryResponse, Error, UpdateCandidateCategoryVariables>({
    mutationFn: (variables: UpdateCandidateCategoryVariables) => updateCandidateCategory(variables),
  });
};


const createCandidateCategory = async (dto: CandidateCategoryDto) => {
  const response = await axiosInstance.post<CandidateCategoryDto>(
    `/api/candidate-category/create`,
    dto
  );
  return response.data;
};
export const useCreateCandidateCategory = (dto: CandidateCategoryDto) => {
  return useMutation<UpdateCandidateCategoryResponse, Error, CandidateCategoryDto>({
    mutationFn: (variables: CandidateCategoryDto) => createCandidateCategory(variables),
  });
};

const deleteCandidateById = async (dto :DeleteCandidateCategoryDto) => {
  const response = await axiosInstance.delete<CandidateCategoryDto>(
    `/api/candidate-category/${dto.currentCategoryId}/${dto.newCategoryId}`,
  );
  return response.data;
};
export const useDeleteCandidateById = (dto: DeleteCandidateCategoryDto) => {
  return useMutation<UpdateCandidateCategoryResponse, Error, DeleteCandidateCategoryDto>({
    mutationFn: (variables: DeleteCandidateCategoryDto) => deleteCandidateById(dto),
  });
};

const deleteCategoryAssociation = async (dto :DeleteCategoryAssociationDto) => {
  const response = await axiosInstance.delete<CandidateCategoryDto>(
    `/api/candidate-category/${dto.currentCategoryId}`,
  );
  return response.data;
};
export const useDeleteCategoryAssociation = (dto: DeleteCategoryAssociationDto) => {
  return useMutation<UpdateCandidateCategoryResponse, Error, DeleteCategoryAssociationDto>({
    mutationFn: (variables: DeleteCategoryAssociationDto) => deleteCategoryAssociation(dto),
  });
};

const addCandidateCategory = async (dto :AddCandidateCategoryDto) => {
  const response = await axiosInstance.patch<AddCandidateCategoryDto>(
    `/api/candidate-category/add-candidate/${dto.currentCategoryId}/${dto.candidateId}`,
    dto
  );
  return response.data;
};
export const UseAddCandidateCategory = (dto: AddCandidateCategoryDto) => {
  return useMutation<UpdateCandidateCategoryResponse, Error, AddCandidateCategoryDto>({
    mutationFn: (variables: AddCandidateCategoryDto) => addCandidateCategory(dto),
  });
};

export const candidateHooks = {
  useCandidateCategories,
  useCandidateCategoriesWithCount,
  useUpdateCandidateCategory,
  useCreateCandidateCategory,
  useDeleteCandidateById,
  UseAddCandidateCategory,
  useDeleteCategoryAssociation
}