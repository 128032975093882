import React, {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router'
import ManageTable from '../../components/manageTable/ManageTable'
import {Spinner} from 'react-bootstrap'
import {useStores} from '../../stores'
import {Link} from 'react-router-dom'
import {observer} from 'mobx-react'
import {getCandidateTitle} from '../../helpers/getCandidateTitle'
import {ROUTES} from '../../helpers/routeConsts'
import {ICandidateShort} from '../../types/candidate/ICandidateShort'
import candidateService from '../../api/candidate.api'
import OperationsList from './components/OperationsList'
import {useTranslation} from 'react-i18next';
import {IDictionaryItem} from "../../types/IDictionaryItem";
import MassUploadResume from "../../components/modals/mass-upload-resume";

type Props = {}

const Index = (props: Props) => {
  const {t} = useTranslation();
  const {status} = useParams()
  const {routerStore} = useStores()

  const [legacyHh, setLegacyHh] = useState(false);
  const [pages, setPages] = useState<React.ReactNode[]>([])
  const [list, setList] = useState<ICandidateShort[] | null>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [sort, setSort] = useState<{ sortBy: String, sortDirection: 'ASC' | 'DESC' }>({
    sortBy: 'id',
    sortDirection: 'DESC'
  })
  const [formValues, setFormValues] = useState<any>({
    candidateCategoryId: "",
  });

  const [dictionaries, setDictionaries] = useState<{ [key: string]: IDictionaryItem[] }>({
    category: [],
  })

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);

    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      updateList(sort);
    }, 300);

    setTimer(newTimer);
  };

  const updateList = useCallback((sorter: {
    sortBy?: String,
    sortDirection?: 'ASC' | 'DESC'
  } = {}, candidateCategoryId?: number) => {
    const {sortBy = 'id', sortDirection = 'DESC'} = sorter;
    const params = {sortBy, sortDirection, legacy_hh: legacyHh || null, candidateCategoryId: candidateCategoryId}

    setList(null)
    candidateService.findAll({
      status,
      query: searchQuery.replaceAll('+', '').replaceAll(' ', ''),
      ...params
    }).then((response) => {
      if (response.status === 200) {
        setList(response.data[0])
      }
    }).catch((e) => {
      console.log(e)
    })
  }, [legacyHh, status, searchQuery, formValues.candidateCategoryId]);

  const handleSort = (sortBy: string) => {
    if (sort.sortBy === sortBy) {
      setSort({...sort, sortDirection: sort.sortDirection == 'ASC' ? 'DESC' : 'ASC'})
      updateList({
        sortBy,
        sortDirection: sort.sortDirection == 'ASC' ? 'DESC' : 'ASC'
      })
    } else {
      setSort({sortBy, sortDirection: 'ASC'})
      updateList({
        sortBy,
        sortDirection: 'ASC'
      })
    }
  }

  const fetchAllCategory = async () => {
    const {data} = await candidateService.candidateCategories()
    setDictionaries((prev) => ({...prev, category: data[0]}));
  }
  const handleChangeSelect = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = event.target;

    setFormValues((prev: any) => ({
      ...prev,
      [name]: +value,
    }));

    updateList(sort, +value);
  }

  useEffect(() => {
    routerStore.setCurrentPath('/candidate/' + status)
    updateList(sort)
    void fetchAllCategory()
  }, [routerStore, status, legacyHh])

  return (
    <>
      <div>
        <h3>{getCandidateTitle(status as string)}</h3>
        <div className={'flex gap-4'}>

          <Link to={ROUTES.CANDIDATE_CREATE}
                className='btn btn-primary mb-3'>{t('AddCandidateButton')}</Link> {/* Переводим кнопку "Добавить кандидата" */}
          <MassUploadResume update={updateList}/>
          {/* Переводим кнопку "Добавить кандидата" */}
        </div>
      </div>
      <ManageTable
        title={t('CandidateListTitle')}
        header={
          <ul className={'flex items-center w-[70%] justify-end'}>
            <select
              className="form-select max-h-[36px] max-w-[25%]"
              id="candidateCategoryId"
              name={'candidateCategoryId'}
              value={formValues.candidateCategoryId}
              required={true}
              onChange={handleChangeSelect}
            >
              <option value="">-Все категории-</option>
              {dictionaries?.category?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>

            <label>
              Кандидаты только с Head Hunter:
              <input
                className={'ml-2'}
                type="checkbox"
                onChange={(e) => setLegacyHh(e.target.checked)}
              />
            </label>
            <li>Всего кандидатов: {list?.length}</li>
            <li>
              <form><input className='form-control' value={searchQuery} onChange={handleInputChange}
                           placeholder={t('SearchInListPlaceholder')}/></form>
            </li>
            {/* Переводим placeholder для поля поиска */}
          </ul>
        }
        footer={<></>}
        pages={pages}
      >
        <thead>
        <tr>
          <th style={{cursor: 'pointer'}} onClick={() => handleSort('id')}>ID {
            sort.sortBy === 'id' && <>{
              sort.sortDirection == 'ASC' ? <i className="fa-solid fa-arrow-down-short-wide"></i> :
                <i className="fa-solid fa-arrow-down-wide-short"></i>
            }</>
          }</th>
          <th style={{cursor: 'pointer'}} onClick={() => handleSort('name')}>{t('NameColumn')} {
            sort.sortBy === 'name' && <>{
              sort.sortDirection == 'ASC' ? <i className="fa-solid fa-arrow-down-short-wide"></i> :
                <i className="fa-solid fa-arrow-down-wide-short"></i>
            }</>
          }</th>
          {/* Переводим заголовок столбца "Имя" */}
          <th style={{cursor: 'pointer'}} onClick={() => handleSort('email')}>{t('EmailColumn')} {
            sort.sortBy === 'email' && <>{
              sort.sortDirection == 'ASC' ? <i className="fa-solid fa-arrow-down-short-wide"></i> :
                <i className="fa-solid fa-arrow-down-wide-short"></i>
            }</>
          }</th>
          {/* Переводим заголовок столбца "Почта" */}
          <th>{t('PhoneColumn')}</th>
          {/* Переводим заголовок столбца "Телефон" */}
          <th></th>
        </tr>
        </thead>
        <tbody>
        {
          list ?
            <>
              {
                list.map((item) => {
                  return <tr key={item.id}>
                    <td className='pt-3'>
                      <div className={'flex gap-2 items-center justify-between'}>
                        {item.id} {item.legacy_hh &&
                          <img className={'w-4 h-4'} src="/images/hh-icon.png" alt="hh"/>}
                      </div>
                    </td>
                    <td className='pt-3'><Link
                      to={`${ROUTES.CANDIDATE_INFO}/${item.id}`}>{item.lastName + ' ' + item.firstName}</Link></td>
                    <td className='pt-2'>{item.email}</td>
                    <td className='pt-2'>{item.phone}</td>
                    <td>
                      <OperationsList id={item.id} update={() => updateList(sort, formValues.candidateCategoryId)}/>
                    </td>
                  </tr>
                })
              }
            </> :
            <tr>
              <td colSpan={5} className='text-center p-5'>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </td>
            </tr>
        }
        </tbody>
      </ManageTable>
    </>
  )
}

export default observer(Index)
