import {axiosInstanceHH} from "../index";
import {IUserHh} from "../../types/user/IUserHH";
import {hhApi} from "./index";

export const getCurrentUserHH = async () =>  {
    return axiosInstanceHH.get<IUserHh>('/user-info')
}

export const getVacancyHH = async (employerId?: string | null) =>  {
    if (!employerId) {
        hhApi.authRedirect()
        return []
    }
    const { data } = await axiosInstanceHH.get<any>(`/vacancy-list/${employerId}`)
    return data.items
}

export const getVacancyById = async (vacancyId: string) =>  {
    return axiosInstanceHH.get('/vacancy-find', {params: {vacancyId}})
}

export const getVacancyResponses = async (vacancyId: string, vacancyIdUKI: string) =>  {
    return axiosInstanceHH.get('/vacancy-responses/', {params: {vacancyId, vacancyIdUKI}})
}

export const getFavorite = async () =>  {
    return axiosInstanceHH.get('/resume_favorites')
}