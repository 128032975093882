import {getCurrentUserHH, getFavorite, getVacancyById, getVacancyHH, getVacancyResponses} from "./vacancy";
import {authRedirect, getToken, refreshToken} from "./auth";
import {loadGroupFiles} from "./load-files";

export const hhApi = {

// auth
    getToken,
    refreshToken,
    authRedirect,

//user
    getCurrentUserHH,

    //vacancy
    getVacancyHH,
    getVacancyById,
    getVacancyResponses,
    getFavorite,


    loadGroupFiles
}