import React, {useState} from "react";
import {candidateHooks, useDeleteCategoryAssociation} from "../../../api/candidate.api";
import {Button, Form, Modal} from "react-bootstrap";
import {toast} from "react-toastify";

type Props = {
  item: any
  update: () => void;
};

const DeleteCategory = ({update, item}: Props) => {
  const [show, setShow] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);

  const {mutate, isSuccess} = candidateHooks.useDeleteCandidateById({
    currentCategoryId: item.id,
    newCategoryId: selectedCategoryId || 0
  });
  const {data: categories} = candidateHooks.useCandidateCategories()
  console.log(categories)

  const {mutate: fullDelete} = candidateHooks.useDeleteCategoryAssociation({
    currentCategoryId: item.id,
  });

  const getListCatefories = () => {
    if (!categories || !categories[0]) return []
    return categories[0].filter((category: any) => category.id !== item.id)
  }
  const listCategories = getListCatefories()
  const handleClose = () => {
    setShow(false);
    setSelectedCategoryId(null);
  };

  const handleShow = () => setShow(true);

  const handleDelete = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedCategoryId !== null) {
      mutate({
        currentCategoryId: item.id,
        newCategoryId: selectedCategoryId
      }, {
        onSuccess: () => {
          handleClose();
          update();
          toast.success('Категория успешно удалена и кандидаты перераспределены!');
        },
        onError: (error) => {
          toast.error(`Ошибка: ${error.message || 'Не удалось удалить категорию'}`);
        },
      });
    }
  };

  const handleFullDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    fullDelete({
      currentCategoryId: item.id,
    }, {
      onSuccess: () => {
        handleClose();
        update();
        toast.success('Категория успешно удалена');
      },
      onError: (error) => {
        toast.error(`Ошибка: ${error.message || 'Не удалось удалить категорию'}`);
      },
    });
  };

  return (
    <div>
      <button
        className="dropdown-item"
        onClick={(e) => {
          e.preventDefault();
          handleShow();
        }}
      >
        Удалить категорию
      </button>

      <Modal size="lg" show={show} onHide={handleClose} keyboard={true}>
        <Modal.Header closeButton>
          <Modal.Title>Удаление категории {item.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleDelete}>
            <Form.Group className="mb-3" controlId="formCategorySelect">
              <Form.Label>
                <span className="text-danger">*</span> Выберите категорию куда будут перенесены кандидаты
              </Form.Label>
              <Form.Control
                as="select"
                value={selectedCategoryId || ""}
                onChange={(e) => setSelectedCategoryId(Number(e.target.value))}
                required
              >
                <option value="">Выберите категорию</option>
                {listCategories?.map((category: any) => (
                  <option key={category.id} value={category.id}>
                    {category.name} ({category.code})
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <div className={'flex gap-4'}>
              <Button disabled={!selectedCategoryId} variant="danger" type="submit">
                Удалить
              </Button>

              <Button onClick={handleFullDelete} variant="danger" type="button">
                Удалить категорию без переноса кандидатов
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DeleteCategory;