import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next';
import ManageTable from "../../components/manageTable/ManageTable";
import {candidateHooks} from "../../api/candidate.api";
import {Spinner} from "react-bootstrap";
import CategoryOperationsList from "./components/category-operation-list";
import CreateCategory from "./components/create-category";
import {useStores} from "../../stores";
import {useLocation} from "react-router-dom";

type Props = {}

const CandidateCategory = (props: Props) => {
  const {t} = useTranslation();

  const [pages, setPages] = useState<React.ReactNode[]>([])

  const location = useLocation();

  const {data, refetch} = candidateHooks.useCandidateCategoriesWithCount();

  const {routerStore} = useStores()

  const listCategory = data?.items || []

  useEffect(() => {
    routerStore.setCurrentPath(location.pathname)
  }, [routerStore, location])

  return (
    <>
      <div>
        <CreateCategory update={refetch}/>
      </div>
      <ManageTable
        header={<></>}
        pages={pages}
        title={'Список категорий'}
        footer={<></>}
      >
        <thead>
        <tr>
          <th style={{cursor: 'pointer'}}>ID</th>
          <th style={{cursor: 'pointer'}}>{t('NameColumn')}</th>
          <th style={{cursor: 'pointer'}}>{t('CandidateCount')} </th>
          <th>{t('vacancy.operations')}</th>
        </tr>
        </thead>
        <tbody>
        {
          listCategory.length ?
            <>
              {
                listCategory.map((item: any) => {
                  return <tr key={item.id}>
                    <td className='pt-3'>
                      <div className={'flex gap-2 items-center justify-between'}>
                        {item.id}
                      </div>
                    </td>
                    <td className='pt-3'>
                      <div className={'flex gap-2 items-center justify-between'}>
                        {item.name}
                      </div>
                    </td>
                    <td className='pt-3'>
                      <div className={'flex gap-2 items-center justify-between'}>
                        {item.candidateCount}
                      </div>
                    </td>
                    <td>
                      <CategoryOperationsList update={refetch} item={item}/>
                    </td>
                  </tr>
                })
              }
            </> :
            <tr>
              <td colSpan={5} className='text-center p-5'>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </td>
            </tr>
        }
        </tbody>
      </ManageTable>
    </>
  )
}

export default CandidateCategory
