import React from 'react'

type Props = {
    title: string,
    children: React.ReactNode
}

const Header = (props: Props) => {
    return (
        <div className="header">
            <p className='title w-fit'>{props.title}</p>
            {props.children}
        </div>
    )
}

export default Header