import React from 'react';
import {useTranslation} from "react-i18next";
import VacancyLoadForm from "./components/vacancy-load-form";

const VacancyLoad = () => {
  const { t } = useTranslation(); // Инициализация хука для локализации

  return (
    <div>
      <h3>{t('vacancyCreate.title')}</h3> {/* Заменяем статичный текст на локализированный */}
      <VacancyLoadForm />
    </div>
  );
};

export default VacancyLoad;