export class CandidateCreateRequest {
    firstName: string
    lastName: string
    email: string
    phone: string
    photo: File | null
    categoryId?: string
    constructor() {
        this.firstName = ''
        this.lastName = ''
        this.email = ''
        this.phone = ''
        this.photo = null
        this.categoryId = ''
    }
}