import axiosInstance, {axiosInstanceHH} from "../index";
import {hhApi} from "./index";
type Token = {
    access_token: string;
    token_type: string;
    refresh_token: string;
    expires_in: number;
}

export const authRedirect = async () => {
    const { data } = await axiosInstance.get('/api/hh/auth');
    window.location.href = data.authUrl;
}

export const getToken = async (code: string): Promise<Token | undefined> => {
    try {
        const {data} = await axiosInstance.get<Token>('/api/hh/auth/callback', { params: { code } })

        sessionStorage.setItem('access_token', data.access_token);
        sessionStorage.setItem('token_type', data.token_type);
        sessionStorage.setItem('refresh_token', data.refresh_token);
        sessionStorage.setItem('expires_in', data.expires_in.toString());

        const url = new URL(window.location.href);
        url.searchParams.delete('code');
        window.history.replaceState(null, '', url.toString());

        const { data: userHH } = await hhApi.getCurrentUserHH()
        sessionStorage.setItem('employerId', userHH?.employer?.id)
        return data
    } catch (e) {

    }
    return undefined
}


export const refreshToken = async (refresh_token: string, originalRequest: any): Promise<Token | undefined> => {
    try {
        const { data } = await axiosInstanceHH.post('/refresh-token', { refresh_token });

        sessionStorage.setItem('access_token', data.access_token);

        originalRequest.headers['Authorization'] = `Bearer ${data.access_token}`;
        return axiosInstanceHH(originalRequest);
    } catch (refreshError) {
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('refresh_token');
    }
    return undefined
}