import axiosInstance from "../../../api";

import React from 'react'
import {toast} from "react-toastify";

type Props = {
  vacancyId: any
  update: () => void
}

const FreeVacancy = (props: Props) => {
  const handleFetch = async () => {
    const loadingToastId = toast.loading("Загрузка откликов началась...");
    try {
      const res = await axiosInstance.post<any>(`api/vacancy/chainVacancyHH`, {
        id: props.vacancyId,
        legacy_hh: props.vacancyId,
        free: true
      })

      toast.update(loadingToastId, {
        render: "Вакансия отвязанна успешно!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      props.update()
    } catch (error) {
      toast.update(loadingToastId, {
        render: "Ошибка при отвязке вакансии!",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  }



  return <>
    <a href="/" className='dropdown-item' onClick={(e) => {
      e.preventDefault()
      handleFetch()
    }}>
      Отвязать вакансию
    </a>

  </>
}

export default FreeVacancy
