import React, { useEffect, useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useUpdateCandidateCategory } from "../../../api/candidate.api";
import {toast} from "react-toastify";

type Props = {
  item: any;
  update: () => void;
};

const EditCategory = (props: Props) => {
  const { item, update } = props;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formValues, setFormValues] = useState({
    name: '',
    code: ''
  });

  const { mutate, isSuccess } = useUpdateCandidateCategory(item.id, formValues);

  const handleUpdate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(
      { id: item.id, dto: formValues },
      {
        onSuccess: () => {
          handleClose();
          update();
          toast.success("Категория успешно обновлена!");
        },
        onError: (error) => {
          toast.error(`Ошибка: ${error.message || "Не удалось обновить категорию"}`);
        },
      }
    );
  };

  useEffect(() => {
    setFormValues({
      name: item.name,
      code: item.code
    });
  }, [item]);

  return (
    <div>
      <button
        className="dropdown-item"
        onClick={(e) => {
          e.preventDefault();
          handleShow();
        }}
      >
        Изменение категории
      </button>

      <Modal size="lg" show={show} onHide={handleClose} keyboard={true}>
        <Modal.Header closeButton>
          <Modal.Title>Изменение категории {props.item.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleUpdate}>
            <Form.Group className="mb-3" controlId="formCategoryName">
              <Form.Label>
                <span className="text-danger">*</span> Название категории
              </Form.Label>
              <Form.Control
                type="text"
                value={formValues.name}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, name: e.target.value }))
                }
                placeholder="Введите название категории"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formCategoryCode">
              <Form.Label>
                {item.code !== 'uploaded' && <span className="text-danger">*</span>}
                Код категории
              </Form.Label>
              <Form.Control
                type="text"
                disabled={item.code === 'uploaded'}
                value={formValues.code}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, code: e.target.value }))
                }
                placeholder="Введите код категории"
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Изменить
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditCategory;