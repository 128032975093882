import React from 'react'
import {Dropdown} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import EditCategory from './edit-category'
import DeleteCategory from "./delete-category";

type Props = {
  item: any
  update: () => void
}

const CategoryOperationsList = (props: Props) => {
  const {t} = useTranslation()
  return <Dropdown autoClose="outside">
    <Dropdown.Toggle variant="light" id="dropdown-basic">
      <i className="fa-solid fa-ellipsis-vertical"></i>
    </Dropdown.Toggle>

    <Dropdown.Menu>
        <EditCategory item={props.item} update={props.update}/>
        <DeleteCategory item={props.item} update={props.update}/>
    </Dropdown.Menu>
  </Dropdown>
}

export default CategoryOperationsList