import React, {useEffect, useState} from 'react';
import {IDictionaryItem} from "../../../types/IDictionaryItem";
import {hhApi} from "../../../api/hh-api";
import {Button} from "react-bootstrap";
import axiosInstance from "../../../api";
import {toast} from "react-toastify";

type FreeVacancy =  {
  isFree: boolean | null,
  vacancyName: string | null
}
const VacancyLoadForm = () => {
  const [dictionaries, setDictionaries] = useState<{ [key: string]: IDictionaryItem[] }>({
    hhVacancy: [],
    ukiVacancy: [],
  })

  const [isFreeVacancy, setIsFreeVacancy] = useState<FreeVacancy>({
    isFree: null,
    vacancyName: null
  })

  const [formValues, setFormValues] = useState<any>({
    hhVacancyId: "",
    ukiVacancyId: "",
  });

  const fetchVacancyHH = async () => {
    const employerId = sessionStorage.getItem('employerId')
    const data = await hhApi.getVacancyHH(employerId)
    setDictionaries((prev) => ({ ...prev, hhVacancy: data }));
  }

  const fetchVacancyUKI = async () => {
    const {data} = await axiosInstance.post<any>(`api/vacancy/findShortFree`)

    setDictionaries((prev) => ({ ...prev, ukiVacancy: data }));
  }
  useEffect(() => {
    fetchVacancyHH()
    fetchVacancyUKI()
  }, []);

  const handleForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!formValues.hhVacancyId && !formValues.ukiVacancyId) {
      toast.warning('Необходимо заполнить хотя бы одно поле!', { autoClose: 3000 });
      return event.preventDefault()
    }
    try {
      const res = await axiosInstance.post<any>(`api/vacancy/chainVacancyHH`, {
        id: formValues.ukiVacancyId,
        legacy_hh: formValues.hhVacancyId
      })
      toast.success('Вакансия успешно связана!', { autoClose: 3000 });
      setFormValues({
        hhVacancyId: '',
        ukiVacancyId: '',
      })
    } catch (e) {
      toast.error('Ошибка при связывании вакансии. Повторите попытку.', { autoClose: 3000 });
      console.log(e)
    }
  }

  const handleChangeSelect = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;

    setFormValues((prev: any) => ({
      ...prev,
      [name]: +value,
    }));
    if (name === 'hhVacancyId') {
      const {data} = await axiosInstance.get<FreeVacancy>(`api/vacancy/free/${+event.target.value}`)
      setIsFreeVacancy(data)
    }

  }


  return (
    <div>
      <form onSubmit={(e) => handleForm(e)} className="white-form-block mt-4 pt-4">
        <div className="form-group mb-5 d-flex">
          <label>
            <span className="text-danger h5">*</span> Вакансия из HH
          </label>
          <select
            className="form-select"
            id="vacancySelect"
            name={'hhVacancyId'}
            value={formValues.hhVacancyId}
            required={true}
            onChange={handleChangeSelect}
            aria-label="HH vacancy select"
          >
            <option value="">-Выберите-</option>
            {dictionaries?.hhVacancy?.map((vacancy) => (
              <option key={vacancy.id} value={vacancy.id}>
                {vacancy.name}
              </option>
            ))}
          </select>
        </div>
        {(!isFreeVacancy.isFree && isFreeVacancy.isFree !== null) && (
          <div className="alert alert-danger" role="alert">
            Данная вакансия привязана к {isFreeVacancy.vacancyName}
          </div>
        )}

        {formValues.hhVacancyId &&
            <>
                <div className="form-group mb-5 d-flex">
                    <label>
                        <span className="text-danger h5">*</span> Вакансия из UKI
                    </label>
                    <select
                        className="form-select"
                        id="vacancySelect"
                        name={'ukiVacancyId'}
                        value={formValues.ukiVacancyId}
                        required={true}
                        onChange={handleChangeSelect}
                        aria-label="HH vacancy select"
                    >
                        <option value="">-Выберите-</option>
                      {dictionaries?.ukiVacancy?.map((vacancy) => (
                        <option key={vacancy.id} value={vacancy.id}>
                          {vacancy.position}
                        </option>
                      ))}
                    </select>
                </div>

            </>


        }

        <Button disabled={!formValues.ukiVacancyId} type="submit" size={'lg'}>Привязать вакансию</Button>
      </form>
    </div>
  );
};

export default VacancyLoadForm;