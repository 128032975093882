import axios, { AxiosInstance } from 'axios';
import { ROUTES } from '../helpers/routeConsts';
import {hhApi} from "./hh-api";

export const API_URL = process.env.REACT_APP_API_URL as string

export const ADMIN_URL = process.env.REACT_APP_ADMIN_URL as string

const axiosInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 1000 * 30,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

export const axiosInstanceHH: AxiosInstance = axios.create({
    baseURL: API_URL + '/api/hh',
    timeout: 1000 * 30,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true
});

export const fileUploadInstance: AxiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 1000 * 30,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  withCredentials: true
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      if (error.response.config.url !== ROUTES.REFRESH 
        && !error.response.config.url.includes(ROUTES.LOGIN)
        && !error.response.config.url.includes(ROUTES.REGISTER)) {
        window.location.href = ROUTES.LOGIN
      }
    } else if (error.response && error.response.status === 500) {
      // window.location.href=ROUTES.HOME
    }
    return Promise.reject(error);
  }
);


axiosInstanceHH.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem('access_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstanceHH.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            // Обновление токена (например, используя refresh_token)
            const refreshToken = sessionStorage.getItem('refresh_token');
            if (refreshToken) {
                return await hhApi.refreshToken(refreshToken, originalRequest)
            } else {
                return hhApi.authRedirect()
            }
        }

        return Promise.reject(error);
    }
);


export default axiosInstance;

