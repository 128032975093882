import React, {useEffect} from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MainLayout from './layouts/main/Layout'
import HomeIndex from '../pages/home/Index'
import AuthLayout from './layouts/auth/Layout'
import LoginPage from '../pages/auth/Login'
import ApplicationIndex from '../pages/application/Index'
import ApplicationCreate from '../pages/application/Create'
import ApplicationEdit from '../pages/application/Edit'
import ApplicationInfo from '../pages/application/Info'
import VacancyCreate from '../pages/vacancy/Create'
import VacancyIndex from '../pages/vacancy/Index'
import VacancyInfo from '../pages/vacancy/Info'
import CandidateIndex from '../pages/candidate/Index'
import CandidateCreate from '../pages/candidate/Create'
import CandidateInfo from '../pages/candidate/Info'

import { ROUTES } from '../helpers/routeConsts'
import NeedCompany from '../pages/auth/NeedCompany'
import RegisterPage from '../pages/auth/Register'
import VacancyCandidats from '../pages/vacancy/Candidats'
import VacancySteps from '../pages/vacancy/Steps'

//admin pages
import DepartmentIndex from '../pages/admin/department/Index'
import DivisionIndex from '../pages/admin/department/Divisions'
import PrivilegeIndex from '../pages/admin/priveleges/Index'
import PersonalQualityIndex from '../pages/admin/personalQuality/Index'


import Profile from '../pages/cabinet/Profile'
import Notifications from '../pages/home/Notifications'
import MyCompanyIndex from '../pages/myCompany/Index'
import ResetPassword from '../pages/auth/ResetPassword'
import ConfirmResetPassword from '../pages/auth/ConfirmResetPassword'
import NeedActivation from '../pages/auth/NeedActivation'
import {hhApi} from "../api/hh-api";
import VacancyLoad from "../pages/vacancy/vacancy-load";
import CandidateCategory from "../pages/candidate/category.page";

type Props = {}

const Router = (props: Props) => {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) {
            void hhApi.getToken(code)

            sessionStorage.setItem('authCode', code);

            console.log('Authorization code:', code);
        }
    }, [window.location.search]);

    return <BrowserRouter>
        <Routes>
            <Route path='/' element={<MainLayout />}>
                <Route index element={<HomeIndex />} />
                <Route path={ROUTES.APPLICATION.replace('/', '') + '/:status'} element={<ApplicationIndex />} />
                <Route path={ROUTES.APPLICATION_CREATE.replace('/', '')} element={<ApplicationCreate />} />
                <Route path={ROUTES.APPLICATION_INFO.replace('/', '') + "/:id"} element={<ApplicationInfo />} />
                <Route path={ROUTES.APPLICATION_EDIT.replace('/', '') + "/:id"} element={<ApplicationEdit />} />
                <Route path={ROUTES.VACANCY_CREATE.replace('/', '') + "/:id"} element={<VacancyCreate />} />
                <Route path={ROUTES.VACANCY_CREATE.replace('/', '') + "/hh/:id"} element={<VacancyCreate />} />
                <Route path={ROUTES.VACANCY_CREATE.replace('/', '')} element={<VacancyCreate />} />
                <Route path={ROUTES.VACANCY.replace('/', '') + '/:status'} element={<VacancyIndex />} />
                <Route path={ROUTES.VACANCY_INFO.replace('/', '') + "/:id"} element={<VacancyInfo />} />

                <Route path={ROUTES.CANDIDATE.replace('/', '') + "/:status"} element={<CandidateIndex />} />
                <Route path={ROUTES.CANDIDATE_CREATE.replace('/', '')} element={<CandidateCreate />} />
                <Route path={ROUTES.CANDIDATE_CATEGORY.replace('/', '')} element={<CandidateCategory />} />
                <Route path={ROUTES.CANDIDATE_INFO.replace('/', '') + "/:id"} element={<CandidateInfo />} />

                <Route path={ROUTES.VACANCY_CANDIDATS.replace('/', '') + "/:id"} element={<VacancyCandidats showComments={false}/>} />
                <Route path={ROUTES.VACANCY_CANDIDATS.replace('/', '') + "/:id/:resumeId"} element={<VacancyCandidats showComments={true}/>} />
                <Route path={ROUTES.VACANCY_STEPS.replace('/', '') + "/:id"} element={<VacancySteps />} />
                <Route path={ROUTES.PROFILE.replace('/', '')} element={<Profile />} />
                <Route path={ROUTES.NOTIFICATIONS.replace('/', '')} element={<Notifications />} />
                <Route path={ROUTES.MY_COMPANY.replace('/', '')} element={<MyCompanyIndex />} />
                {/* admin pages */}
                <Route path={ROUTES.ADMIN_DEPARTMENT.replace('/', '')} element={<DepartmentIndex />} />
                <Route path={ROUTES.ADMIN_DIVISION.replace('/', '') + "/:parentId"} element={<DivisionIndex />} />
                <Route path={ROUTES.ADMIN_PRIVILEGE.replace('/', '')} element={<PrivilegeIndex />} />
                <Route path={ROUTES.ADMIN_PERSONAL_QUALITY.replace('/', '')} element={<PersonalQualityIndex />} />
                <Route path={ROUTES.VACANCY_LOAD_HH.replace('/', '')} element={<VacancyLoad />} />
            </Route>
            <Route path='/auth' element={<AuthLayout />}>
                <Route path='login' element={<LoginPage />} />
                <Route path='register' element={<RegisterPage />} />
                <Route path='register/:token' element={<RegisterPage />} />
                <Route path='need-company' element={<NeedCompany />} />
                <Route path='reset-password' element={<ResetPassword/>}/>
                <Route path="confirm-reset-password/:key" element={<ConfirmResetPassword/>}/>
                <Route path="need-activate" element={<NeedActivation/>}/>
            </Route>
        </Routes>
    </BrowserRouter>
}

export default Router