import React, { useState } from "react";
import {candidateHooks} from "../../../api/candidate.api";
import { Button, Form, Modal } from "react-bootstrap";
import {toast} from "react-toastify";

type Props = {
  update: () => void;
};
const CreateCategory = ({ update }: Props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setFormValues({
      name: "",
      code: ""
    })
  }
  const handleShow = () => setShow(true);

  const [formValues, setFormValues] = useState({
    name: "",
    code: "",
  });

  const { mutate, isSuccess } = candidateHooks.useCreateCandidateCategory(formValues);

  const handleCreate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutate(formValues, {
      onSuccess: () => {
        handleClose();
        update();
        toast.success("Категория успешно создана!");
      },
      onError: (error: any) => {
        toast.error(`Ошибка: ${error?.response?.data?.message || "Не удалось создать категорию"}`);
      },
    });
  };

  return (
    <div>
      <button
        className="btn btn-primary mb-4"
        onClick={(e) => {
          e.preventDefault();
          handleShow();
        }}
      >
        Добавить категорию
      </button>

      <Modal size="lg" show={show} onHide={handleClose} keyboard={true}>
        <Modal.Header closeButton>
          <Modal.Title>Создание новой категории</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleCreate}>
            <Form.Group className="mb-3" controlId="formCategoryName">
              <Form.Label>
                <span className="text-danger">*</span> Название категории
              </Form.Label>
              <Form.Control
                type="text"
                value={formValues.name}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, name: e.target.value }))
                }
                placeholder="Введите название категории"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formCategoryCode">
              <Form.Label>
                <span className="text-danger">*</span> Код категории
              </Form.Label>
              <Form.Control
                type="text"
                value={formValues.code}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, code: e.target.value }))
                }
                placeholder="Введите код категории"
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Создать
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateCategory;
