import React from 'react'
import {hhApi} from "../../../api/hh-api";
import {toast} from "react-toastify";

type Props = {
  vacancyIdUKI: any
  legacy_id: string
  update: () => void
}

const AddVacancy = (props: Props) => {
  const handleFetch = async () => {
    const loadingToastId = toast.loading("Загрузка откликов началась...");
    try {
      const res = await hhApi.getVacancyResponses(props.legacy_id, props.vacancyIdUKI);

      toast.update(loadingToastId, {
        render: "Загрузка завершена успешно!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      props.update()
    } catch (error) {
      toast.update(loadingToastId, {
        render: "Ошибка при загрузке откликов!",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  }



  return <>
    <a href="/" className='dropdown-item' onClick={(e) => {
      e.preventDefault()
      handleFetch()
    }}>
      Загрузить кандидатов из HH
    </a>

  </>
}

export default AddVacancy