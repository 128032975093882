import React, {useEffect, useRef, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import candidateService from '../../api/candidate.api'
import {hhApi} from "../../api/hh-api";
import {IDictionaryItem} from "../../types/IDictionaryItem";
import {toast} from "react-toastify";

type Props = {
  update: () => void;
}

const MassUploadResume = (props: Props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
  };

  const [uploadedCategoryId, setUploadedCategoryId] = useState<string | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [formValues, setFormValues] = useState<any>({
    candidateCategoryId: "",
  });

  const [dictionaries, setDictionaries] = useState<{ [key: string]: IDictionaryItem[] }>({
    category: [],
  })
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleClearAll = () => {
    setFiles([])
    setFormValues({category: ''})
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }
  const handleUpload = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (files.length === 0) {
      alert("Please select files to upload");
      return;
    }

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files[]", file);
    });

    const categoryId = formValues.candidateCategoryId || uploadedCategoryId;

    try {
      const response = await hhApi.loadGroupFiles(formData, categoryId);
      props.update()
      toast.success(`Кандидаты успешно загруженны`, {
        autoClose: 5000,
      });
      handleClearAll()
      handleClose()
    } catch (error: any) {
      console.log(error);

      if (error.response) {
        const {status, message, error: errorDetail} = error.response.data;

        const messageRu = message === 'RESUME_WITH_NAME_ALREDY_EXIST' ? 'Резюме с данным названием уже существует' : ''
        const errorMessage = `${messageRu}: ${errorDetail || 'Нет дополнительных деталей об ошибке'}`;

        toast.error(`Error ${status} - ${errorMessage}`, {
          autoClose: 5000,
        });
      } else {
        toast.error('An unknown error occurred', {
          autoClose: 5000,
        });
      }
    }
  };

  const fetchAllCategory = async () => {
    const {data} = await candidateService.candidateCategories()
    setDictionaries((prev) => ({...prev, category: data[0]}));
  }

  const handleChangeSelect = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = event.target;

    setFormValues((prev: any) => ({
      ...prev,
      [name]: +value,
    }));
  }

  const handleRemoveItem = (index: number) => {
    setFiles((prevList) => prevList.filter((_, i) => i !== index));

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);

      const filteredFiles = newFiles.filter((file) => {
        if (file.name.startsWith(".")) {
          return false;
        }

        return !files.some(
          (existingFile) =>
            existingFile.name === file.name && existingFile.type === file.type
        );
      });

      setFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      await fetchAllCategory();
      const {data: categoryForUploads} = await candidateService.candidateCategoriesByCode('uploaded');

      if (categoryForUploads?.id) {
        setUploadedCategoryId(categoryForUploads.id.toString());
      }
    };

    fetchInitialData()
  }, [])



  return <>
    <Button className='mb-3' onClick={handleShow}>
      + Массовая загрузка резюме
    </Button>

    <Modal show={show} size='lg' onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Массовая загрузка резюме</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleUpload}>
          <Form.Group className='mb-3'>
            <Form.Label>
              Выберите папку загружаемых резюме
            </Form.Label>
            <Form.Control
              ref={fileInputRef}
              onChange={handleFilesChange}
              type="file"
              multiple
              {...{webkitdirectory: "true"}}
            />
            <Form.Label className={'mt-4'}>
              Выберите категорию куда будут загруженны резюме (по умолчанию "Загруженные")
            </Form.Label>
            <select
              className="form-select max-h-[36px]"
              id="candidateCategoryId"
              name={'candidateCategoryId'}
              value={formValues.candidateCategoryId}
              onChange={handleChangeSelect}
            >
              <option value={""}>-Выберете категорию-</option>
              {dictionaries?.category?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>

            <div className={'my-4 max-h-[25rem] overflow-y-auto '}>
              {files && Array.from(files).map((file, index) => (
                <div
                  key={index}
                  className="file-item flex items-center justify-between border border-gray-300 rounded-lg p-4 mb-4 mx-2 shadow-sm"
                >
                  <div>
                    <p className="text-sm font-medium text-gray-700">
                      <strong>Имя файла:</strong> {file.name}
                    </p>
                    <p className="text-sm text-gray-600">
                      <strong>Размер:</strong> {(file.size / 1024).toFixed(2)} KB
                    </p>
                    <p className="text-sm text-gray-600">
                      <strong>Тип:</strong> {file.type || 'Неизвестно'}
                    </p>
                  </div>
                  <button
                    className="text-red-500 hover:text-red-700 focus:outline-none ml-4"
                    type={'button'}
                    onClick={() => handleRemoveItem(index)}
                    title="Удалить файл"
                  >
                    <i className="fas fa-trash-alt text-lg"></i>
                  </button>
                </div>
              ))}
            </div>

            <div className={'flex gap-4'}>
              <Button disabled={!files?.length} type={'submit'}>
                {`Добавить всего: ${files?.length || 0} резюме`}
              </Button>

              <Button onClick={handleClearAll} disabled={!files?.length} type={'button'}>
                {`Очистить форму`}
              </Button>
            </div>

          </Form.Group>
        </Form>

      </Modal.Body>
    </Modal>
  </>
}

export default MassUploadResume;